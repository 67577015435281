import axios from 'axios';

const baseRefreshUrl = "https://account-api.dev.wetu.com/api/v1/RefreshToken/";

export const refreshToken = async () => {
    return axios.post(baseRefreshUrl + "RefreshToken", {}, {
        withCredentials: true
    });
}



