import { useEffect } from 'react';

export const LoginPage = () => {
    const domain = "login.dev.wetu.com";
    const returnURL = "https://backoffice.dev.wetu.com/dashboard";

    useEffect(() => {
        window.location.assign(`https://${domain}?ReturnUrl=${returnURL}`);
    }, []);

    return (null);
}