import axios from 'axios';
import { IWetuAccount, IWetuUser } from '../slices/account.slice';

const baseProfileUrl = "https://account-api.dev.wetu.com/api/v1/Account/";

export const logout = async() => {
  return axios.post(baseProfileUrl + "Logout", {}, {
    withCredentials: true
  });
}

export const getAccounts = async () => {
    return axios.get<IWetuAccount[]>(baseProfileUrl + "GetAccounts", { withCredentials: true});
}

export const getUsers = async (accountId: string) => {
  return axios.get<IWetuUser[]>(baseProfileUrl + `GetUsers?accountId=${accountId}`, { withCredentials: true});
}




