import React from 'react';
import { Container } from 'react-bootstrap';
import { AccountTable } from './table/account-table';


export const ClientManagementPage = () => {

  return (
    <Container>
          Client Management
          <AccountTable></AccountTable>
    </Container>
  )
}