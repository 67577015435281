import { RootState } from 'app/root-reducer';
import React from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalToast } from './toast.slice';
import './toast.scss';

export const Toaster =() => {
  const dispatch = useDispatch();
  const { show, title, message } = useSelector((state: RootState) => state.toast);

  const hide = () => {
    dispatch(hideGlobalToast());
  }

  return (
    <React.Fragment>
      {
        show && (
            <div className="toastWrapper">
              <Toast onClose={hide} show={show} delay={3000} autohide>
                <Toast.Header>
                  <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                  <strong className="mr-auto">{title}</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
              </Toast>
            </div>
          )
      }
    </React.Fragment>
  )
};