import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Toast } from "./types";

const initialState = {
  show: false,
  title: "",
  message: ""
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
      showGlobalToast(state, action: PayloadAction<Toast>) {
        state.show = true;
        state.message = action.payload.message;
        state.title = action.payload.title;
      },
      hideGlobalToast(state) {
        state.show = false;
        state.message = "";
        state.title = "";
      }
    }
});

export const {
  showGlobalToast,
  hideGlobalToast
} = toastSlice.actions;

export default toastSlice.reducer;