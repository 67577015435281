import React from 'react';
import { RootState } from 'app/root-reducer';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom'

import { DashboardPage } from 'pages/dashboard/dashboard.page';
import { ClientManagementPage } from 'pages/client-management/client-management.page';
import { PersonalDetailsPage } from "pages/personal-details/personal-details.page";
import { OrganisationDetailsPage } from "pages/organisation-details/organisation-details.page";
import { UserManagementPage } from "pages/user-management/user-management.page";
import { SettingsPage } from "pages/settings/settings.page";
import { AccountHistoryPage } from "pages/account-history/account-history.page";

import { NavigationBar } from "pages/home/features/navigation-bar/navigation-bar";

import { Col, Container, Row, ListGroup, Accordion, Form, } from 'react-bootstrap';

export const HomePage = () => {
  const { isAdmin } = useSelector((state: RootState) => state.authentication);

  const routes = [
      {
        label: "Dashboard",
        path: "/Dashboard",
        component: <DashboardPage />,
        default: true
      },
      {
        label: "Admin",
        hidden: !isAdmin,
        path: "/Admin",
        subRoutes: [
          {
            label: "Organisation Details",
            path: "/OrganisationDetails",
            component: <OrganisationDetailsPage />
          },
          {
            label: "User Management",
            path: "/:accountId/UserManagement",
            component: <UserManagementPage />
          },
          {
            label: "Settings",
            path: "/Settings",
            component: <SettingsPage />
          },
          {
            label: "Account History",
            path: "/AccountHistory",
            component: <AccountHistoryPage />
          }
        ]
      },
      {
        label: "Client Management",
        path: "/ClientManagement",
        component: <ClientManagementPage />,
        hidden: !isAdmin
      },
      {
        label: "Personal Details",
        path: "/PersonalDetails",
        component: <PersonalDetailsPage />
      }
    ];

    return (
      <Container fluid> 
        <Row>
          <NavigationBar />
        </Row>
        <Router>
          <Row>
            <Col md={3}> 
              <ListGroup>
                {routes.map((route, a) => (
                  (route.label && !route.hidden && route.subRoutes) ?
                    <Accordion key={a} as={ListGroup.Item} defaultActiveKey="0">
                      <Accordion.Toggle as={Form.Label} eventKey="0">
                        {route.label}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <ListGroup variant="flush">
                          {route.subRoutes.map((subRoute, b) => (
                            <ListGroup.Item key={`${a}.${b}`}>
                              <Link to={`${route.path}${subRoute.path}`}>{subRoute.label}</Link>
                            </ListGroup.Item>
                          ))}
                        </ListGroup >
                      </Accordion.Collapse>
                    </Accordion>
                  :
                  (route.label && !route.hidden) &&
                    <ListGroup.Item key={a}>
                      <Link to={route.path}>{route.label}</Link>
                    </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col md={9}>
              <Switch>
                {routes.map((route, a) => (
                  (route.subRoutes) ?
                    route.subRoutes.map((subRoute, b) => (
                      <Route exact={true} key={`${a}.${b}`} path={`${route.path}${subRoute.path}`} children={subRoute.component} />
                    ))
                  :
                    <Route exact={true} key={a} path={route.path} children={route.component} />
                ))}
                <Redirect from="*" to={routes.find(x => x.default)?.path!} />
              </Switch>
            </Col>
          </Row>
        </Router>
      </Container>
    )
}