import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { logout as api_logout } from "api/account.service";

import { refreshToken as api_refreshToken } from "api/refreshToken.service";


const initialState = {
    showLoginPage:  false,
    username: "",
    isAdmin: false,
    userPhotoURL: ""
};

interface IWetuIdToken {
    username: string;
    role: string;
    userPhotoURL: string;
};

export const logout = createAsyncThunk('authentication/logout', async (arg: void, { rejectWithValue }) => {
    await api_logout().catch((err) => {
        console.log(err, "authentication/logout");

        return rejectWithValue(false);
    });
});

export const refreshToken = createAsyncThunk('authentication/refreshToken', async (action: AsyncThunk<any, any, any>, { dispatch, rejectWithValue }) => {
    await api_refreshToken().then(() => {
        dispatch(action);
    }).catch(() => {
        dispatch(logout());

        return rejectWithValue(false); 
    });
});

const authentication = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setStateFromWetuIdToken(state, action: PayloadAction<IWetuIdToken>) {
            state.username = action.payload.username;
            state.isAdmin = action.payload.role.toLowerCase() === "admin";
            state.userPhotoURL = action.payload.userPhotoURL;
        },
        redirectToLoginPage(state) {
            state.showLoginPage = true;
        }
    },
    extraReducers: builder => {
        builder.addCase(logout.fulfilled, (state) => {
            state.showLoginPage = true;
        })
    },
});

export const {
    setStateFromWetuIdToken,
    redirectToLoginPage
} = authentication.actions;

export default authentication.reducer;