import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { AppDispatch } from 'app/store'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { getUsers } from 'slices/account.slice';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';


export const UserTable = () => {
    const dispatch: AppDispatch = useDispatch();
    let { accountId } = useParams<{accountId: string}>();
    const { users, loading } = useSelector((state: RootState) => state.account);

    useEffect(() => {
        dispatch(getUsers(accountId));
    }, [dispatch]);

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name & Email</th>
                    <th>Username</th>
                    <th>Verification</th>
                    <th>Last Login</th>
                    <th>Role</th>
                </tr>
            </thead>
            {!loading &&
                <tbody>

                    {users.map((user, i) => {                  
                            return <tr key={i}>
                                <td>{user.name} {user.surname} <br></br> {user.email}</td>
                                <td>{user.username}</td>
                                <td>{user.emailVerified ? "Verified" : "Not Verified"}</td>
                                <td>{dayjs(user.lastLogin, 'MMMM Do YYYY, h:mm:ss a').toString()}</td>
                                <td>{user.role}</td>
                            </tr>                  
                            }
                        )    
                    }                   
                </tbody>
            }
        </Table>
    )
}