import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAccounts as api_getAccounts, getUsers as api_getUsers } from "api/account.service";

import { refreshToken } from "slices/authentication.slice";

const initialState = {
    accounts: [] as IWetuAccount[],
    users: [] as IWetuUser[],
    loading: false
};

export interface IWetuAccount {
    id: string,
    name: string,
    tier: string,
    type: string,
    userCount: number,
    verified: boolean,
    lastLogin: string,
};

export interface IWetuUser{
    id: string,
    name: string,
    surname: string,
    username: string,
    lastLogin: string,
    role: string,
    email: string,
    emailVerified: boolean
}

export const getAccounts = createAsyncThunk('accounts/getall', async (arg: void, { dispatch, rejectWithValue }) => {
    try {
        var response = await api_getAccounts();   
            
        return response.data;
    }
    catch(err) {
        if (err.response.status === 401) {
            dispatch(refreshToken(getAccounts));
        }

      return rejectWithValue(false);
    }
});

export const getUsers = createAsyncThunk('accounts/getusers', async ( accountId:string, { dispatch, rejectWithValue }) => {
    try {

        var response = await api_getUsers(accountId);   
            
        return response.data;
    }
    catch(err) {
        if (err.response.status === 401) {
            dispatch(refreshToken(getAccounts));
        }

      return rejectWithValue(false);
    }
});

const accounts = createSlice({
    name: 'accounts',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAccounts.fulfilled, (state, action) => {
            state.accounts = action.payload;
            state.loading = false;
        })
        builder.addCase(getAccounts.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload;
            state.loading = false;
        })
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
        })
    },
});

export const {} = accounts.actions;

export default accounts.reducer;
