import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { AppDispatch } from 'app/store'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { getAccounts } from 'slices/account.slice';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

export const AccountTable = () => {
    const dispatch: AppDispatch = useDispatch();
    const { accounts, loading } = useSelector((state: RootState) => state.account);

    const history = useHistory();

    useEffect(() => {
        dispatch(getAccounts());
    }, [dispatch]);

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Tier</th>
                    <th>Type</th>
                    <th>UserCount</th>
                    <th>Verification</th>
                    <th>Last Login</th>
                </tr>
            </thead>
            {!loading &&
                <tbody>

                    {accounts.map((account, i) => {                  
                            return <tr key={i} onClick={() => {history.push(`Admin/${account.id}/UserManagement`)}}>
                                <td>{account.id}</td>
                                <td>{account.name}</td>
                                <td>{account.tier}</td>
                                <td>{account.type}</td>
                                <td>{account.userCount}</td>
                                <td>{account.verified? "Verified" : "Not Verified"}</td>
                                <td>{dayjs(account.lastLogin, 'MMMM Do YYYY, h:mm:ss a').toString()}</td>
                            </tr>                  
                            }
                        )    
                    }                   
                </tbody>
            }
        </Table>
    )
}