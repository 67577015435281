import React from 'react';
import { Container } from 'react-bootstrap';
import { UserTable } from './table/user-table';

export const UserManagementPage = () => {

  return (
    <Container>
      User Management
      <UserTable></UserTable>
    </Container>
  )
}