import React from 'react';
import { Container } from 'react-bootstrap';

export const DashboardPage = () => {

  return (
    <Container>
      Dashboard
    </Container>
  )
}