import { combineReducers } from '@reduxjs/toolkit';

import toast from 'components/toast/toast.slice';

import authentication from 'slices/authentication.slice';

import account from 'slices/account.slice';

const rootReducer = combineReducers({
	toast,
	authentication,
	account
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;