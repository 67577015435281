import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'app/root-reducer';

import { LogoutButton } from "pages/home/features/logout-button";

import { Navbar, Image }  from 'react-bootstrap';

import "./navigation-bar.scss";

export const NavigationBar = () => {
  const { username } = useSelector((state: RootState) => state.authentication);  

  return ( 
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <Image alt="" src={require("./images/wetuLogo-inverted.png")} />
        <span>back office</span>
      </Navbar.Brand>
      <div>
        <Image src={require("./images/icon_contact.svg")} roundedCircle />
        <span className="username">{username}</span>
        <LogoutButton />
      </div>
    </Navbar>
  )
}