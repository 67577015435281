import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import {  Dictionary } from "@reduxjs/toolkit";

import jwt_decode from "jwt-decode";

import { HomePage } from 'pages/home/home.page';

import { LoginPage } from 'pages/login/login.page'
import { Toaster } from 'components/toast/toast';
import PrivateRoute from 'components/private-route';

import { setStateFromWetuIdToken, redirectToLoginPage } from "slices/authentication.slice";

import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import './App.scss';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let cookies : Dictionary<string> = {};
    
    document.cookie.split(';').forEach((el) => {
      let [key,value] = el.split('=');
      cookies[key.trim()] = value;
    });

    if (cookies["wetu_id_token"]) 
    {
      dispatch(setStateFromWetuIdToken(jwt_decode(cookies["wetu_id_token"])));
    }
    else 
    {
      dispatch(redirectToLoginPage());
    }
  }, [dispatch]);

  return (
    <Router>
      <Toaster />
      <Switch>
        <Route exact path="/Login" component={LoginPage}/>
        <PrivateRoute path='/' component={HomePage} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}