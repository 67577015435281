import React from 'react';
import { Container } from 'react-bootstrap';

export const AccountHistoryPage = () => {

  return (
    <Container>
      Account History
    </Container>
  )
}