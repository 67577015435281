import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'app/root-reducer';

import { Route, RouteComponentProps, RouteProps, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { showLoginPage } = useSelector((state: RootState) => state.authentication);  

  if (!Component) {
    return null;
  }

  return (
      <Route {...rest} render={(props: RouteComponentProps<{}>) => 
          (!showLoginPage ? 
              <Component {...props} /> : 
              <Redirect to='/Login' />
          )} />
  );
}

export default PrivateRoute;
