import React from 'react';
import { useDispatch } from 'react-redux';

import { logout } from "slices/authentication.slice";

import { Button }  from 'react-bootstrap';

export const LogoutButton = () => {
  const dispatch = useDispatch();

  const btnLogout_onClick = (e: any) => {
    dispatch(logout());
  }

  return ( 
    <React.Fragment>
      <Button onClick={btnLogout_onClick}>
          Logout
      </Button>
    </React.Fragment>
  )
}