import React from 'react';
import { Container } from 'react-bootstrap';

export const OrganisationDetailsPage = () => {

  return (
    <Container>
      Organisation Details
    </Container>
  )
}